.game--wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.toppart-diff {
  padding: 20px 50px;
  -webkit-box-shadow: 0px 7px 29px 1px rgba(93, 111, 166, 1);
  -moz-box-shadow: 0px 7px 29px 1px rgba(93, 111, 166, 1);
  box-shadow: 0px 7px 29px 1px rgba(93, 111, 166, 1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 10px 10px 20px 10px;
}

.section--29485 {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.generate-game {
  background-color: var(--color-primary);
  color: #fff;
  padding: 10px;
  border: 0px;
  border-radius: 10px;
}

.search-Btn-00239 {
  border-radius: 10px;
  padding: 10px;
  background-color: var(--color-third);
  color: #fff;
  border: none;
}

.nameDisplay-002 {
  font-family: poppins;
  font-weight: 600;
}

#dateGameWrrapper {
  background-color: #0080ff;
  padding: 5px;
  color: #ffffff;
  border: none;
  outline: none;
  border-radius: 5px;
}

::-webkit-calendar-picker-indicator {
  background-color: #ffffff;
  padding: 5px;
  cursor: pointer;
  border-radius: 3px;
}

.baziWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 50px;
}

.baziChilds {
  display: flex;
  flex-direction: column;
  gap: 0px;
  -webkit-box-shadow: 0px 0px 10px 0px var(--color-primary);
  -moz-box-shadow: 0px 0px 10px 0px var(--color-primary);
  box-shadow: 0px 0px 10px 0px var(--color-primary);
  border-radius: 15px;
}

.baziName {
  font-weight: 600;
}

.baziDetails {
  padding: 10px 30px;
  font-family: poppins;
  align-items: center;
}

.baziActions {
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: center;
  background-color: var(--color-secondary);
  color: #fff;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  padding: 10px;
  font-family: poppins;
  align-items: center;
}

.baziAction-Child button {
  padding: 7px;
  border-radius: 5px;
  border: none;
  background-color: #fff;
  border: 1px solid var(--color-primary);
}

.gameTime,
.baziNumbers {
  display: flex;
  gap: 10px;
}

.changeStatus {
  width: 400px;
}

.gameName--modalStatus {
  font-family: poppins;
  font-size: 1.6rem;
}

.gameDets--status-modal {
  font-family: inter;
  font-weight: 400;
}

.status-ActionModal {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.status-ActionModal p {
  font-family: poppins;
  font-weight: 500;
}

#selectModalStatus {
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submitActionModalStatus {
  padding: 10px 20px;
  color: #fff;
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
}

.actionToSubmit {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  margin: 10px 0px 0px 0px;
}

.modalResultWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* padding: 0px 40px; */
  display: flex;
  align-items: center;
}


.modalWrapper-Heading {
  font-family: poppins;
  font-size: 1.6rem;
  font-weight: 500;
}

.singleResultWrap,
.resultDiv-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  row-gap: 5px;
}

.btnResult-ModalDeclare {
  width: 90%;
  padding: 10px 20px;
  /* background-color: rgb(186, 184, 184); */
  background-color: #fff;
  border: 1px solid #c0c0c0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: poppins;
  font-size: 1.2rem;
  border-radius: 15px;
}

.resultWrap {
  gap: 10px;
  /* padding: 0px 10px 0px 20px; */
  display: flex;
  flex-direction: column;
  width: 90%;
}

.resultDiv-grid {
  width: 100%;
  height: 100;
}

.section--29485 input {
  height: 30px;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  font-family: poppins;
  font-size: .8rem;
  padding: 10px;
}

.section-553872 #search-userPanel {
  background-color: var(--color-primary);
  color: #fff;
  padding: 10px;
  border: 0px;
  border-radius: 10px;
}

.section-553872 {
  display: flex;
  gap: 10px;
}

.section-553872 #addUser-usePanel {
  background-color: #fff;
  color: var(--color-primary);
  padding: 10px;
  border: 1px solid var(--color-primary);
  border-radius: 10px;
}

.v-Heading-UserList {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.v-Heading-UserList p {
  font-family: poppins;
  font-size: 2rem;
  font-weight: 500;
}

.info-Inputs-user {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 10px;
  align-content: space-between;
  /* align-items: stretch; */
}

.addUserModal {
  width: 100%;
}

.info-Inputs-user label {
  display: flex;
  flex-direction: column;
  font-family: poppins;
  font-size: .8rem;
  font-weight: 500;
  gap: 5px;
}

.info-Inputs-user label input {
  height: 30px;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
  padding: 10px;
}

.info-Inputs-user label select {
  height: 30px;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
  padding: 0px 10px;
}

.addUserModalHeading {
  font-family: poppins;
  font-size: 1.4rem;
  font-weight: 500;
}

.addUserSubmit {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #0080ff;
  padding: 10px;
  border-radius: 15px;

  &:hover {
    background-color: #0070cc;
  }

  border: none;
}

.section--294851,
.section-5538721 {
  width: 100%;
}


.section--294851 label p,
.section-5538721 label p {
  font-family: poppins !important;
  font-size: 1rem;
  font-weight: 500;
  padding-left: 5px;
}

.section--294851 label input,
.section-5538721 label input {
  width: 90%;
  padding: 10px;
  border: 1px solid #c0c0c0;
  border-radius: 15px;
}

.section--294851 label select {
  width: 90%;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #c0c0c0;
}

.submitSearchQueryProfitLoss {
  width: 90%;
  padding: 10px;
  margin-top: 1rem;
  color: #fff;
  font-family: poppins;
  font-weight: 500;
  border-radius: 15px;
  border: none;
  background-color: var(--color-primary);
}

.redBackground {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.result-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: poppins;

}

.result-display p {
  margin-bottom: 0px !important;
  font-weight: 600;
}

.result-display p span {
  font-weight: 500;
  font-family: inter;
}

.result-display button {
  padding: 10px;
  border: none;
  background-color: var(--color-third);
  color: #fff;
  font-family: poppins;
  font-size: 1rem;
  margin-top: 10px;
  border-radius: 10px;
}

.greenBackground {
  background-color: var(--color-third) !important;
  color: #fff;
}

.yellowBackground {
  background-color: yellow;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.homePage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* height: 400px; */
  padding-top: 150px;
  font-size: 2rem;
  font-family: poppins;
}

.amount-btnResult-ModalDeclare {
  font-size: .9rem;
  font-weight: 600;
}

.amount-btnResult-ModalDeclare {
  font-size: 8.5px;
  font-weight: 600;
}